import React, { useState } from 'react';
import { CssBaseline, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../sidebar/Sidebar';
import Profile from './Profile';
import Menu from './Menu';

const ProfileWithSidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          drawerWidth={drawerWidth}
          content={Menu}
      />
      <Box
          component="main"
          sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          height: '100vh',
          overflow: 'hidden', // Prevent double scrollbars
          }}
      >
          <Profile />
      </Box>
    </Box>
  );
};

export default ProfileWithSidebar;