import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { BusinessCenter, Flight, Home, Train } from '@mui/icons-material';

const WorkArrangement = () => (
  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <BusinessCenter sx={{ mr: 1 }} /> Work Arrangement
    </Typography>

    <List dense>
      <ListItem>
        <ListItemText 
          primary="Hybrid setup" 
          secondary="3 days in London HQ, 2 days remote" 
        />
      </ListItem>
      <ListItem>
        <ListItemText 
          primary="Typical week" 
          secondary="Mon-Wed: In-office, Thu-Fri: Remote" 
        />
      </ListItem>
    </List>

    <Divider sx={{ my: 2 }} />

    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Train sx={{ mr: 1 }} /> Current commute
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      50-minute train commute from Surrey to London office
    </Typography>

    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Flight sx={{ mr: 1 }} /> Current travel
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Travels internationally 1 week per month on average, primarily to New York, Singapore, and Berlin offices
    </Typography>

    <Divider sx={{ my: 2 }} />
    
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Home sx={{ mr: 1 }} /> Location flexibility
    </Typography>
    <List dense>
      <ListItem>
        <ListItemText 
          primary="Open to relocation" 
          secondary="Preferences for locations with international schools and good environment for family" 
        />
      </ListItem>
      <ListItem>
        <ListItemText 
          primary="UK work preferences" 
          secondary="Unlikely to work elsewhere in the UK unless office time is limited" 
        />
      </ListItem>
    </List>
  </Paper>
);

export default WorkArrangement;