import React, { useState, useRef } from 'react';
import { CssBaseline, Box, useMediaQuery, Button } from '@mui/material';
import Divider from '@mui/material/Divider';  // Separate import for Divider
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from '../sidebar/Sidebar';
import ChatContainer from '../chat/ChatContainer';
import ChatAndArtifactsContainer from '../chat/ChatAndArtifactsContainer';

const ChatWithProgress = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;
  const inviteCode = Cookies.get('invite_code');
  const chatRef = useRef(null);
  const { loginWithRedirect } = useAuth0();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const CustomChatContainer = () => (
    <div ref={chatRef}>
      <ChatContainer
        websocketUrl={`/api/introduction/${inviteCode}`}
        requireAuthentication={false}
      />
      <Divider sx={{m:2}}>or</Divider>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 1, ml: 2 }}
        onClick={() => loginWithRedirect({
          redirect_uri: '/onboarding'
        })}
      >
        I'm happy, let's go!
      </Button>
    </div>
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        content={() => null}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {inviteCode && (
          <ChatAndArtifactsContainer
            ChatComponent={CustomChatContainer}
            ProfileComponent={() => null}
          />
        )}
      </Box>
    </Box>
  );
};

export default ChatWithProgress;