import { useState, useEffect } from 'react';

export const useProfile = (fetchFunction) => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function fetchProfile() {
      if (!fetchFunction) return; // Don't attempt to fetch if no function provided
      
      try {
        setLoading(true);
        const data = await fetchFunction();
        if (mounted) {
          setProfile(Array.isArray(data) ? data[0] : data);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err.message || 'Failed to fetch profile data');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchProfile();

    return () => {
      mounted = false;
    };
  }, [fetchFunction]);

  return { profile, error, loading, setProfile };
};
