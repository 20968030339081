import { ChatInput } from './ChatInput';
import { MessageList } from './MessageList';
import { ConnectionStatus } from './ConnectionStatus';

export const ChatUI = ({
  messages,
  isConnected,
  isLoading,
  onSendMessage,
  isMobile
}) => {
  return (
    <>
      <ConnectionStatus isConnected={isConnected} />
      
      <MessageList 
        messages={messages}
        isMobile={isMobile}
        isLoading={isLoading}
      />
      
      <ChatInput 
        onSendMessage={onSendMessage}
        isLoading={isLoading}
        disabled={!isConnected}
      />
    </>
  );
};