import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemText,
  Box,
  Chip,
  Divider,
  Alert
} from '@mui/material';
import {
  AttachMoney as MoneyIcon,
  CardGiftcard as BonusIcon,
  ShowChart as SharesIcon,
  AccountBalance as PensionIcon,
  LocalOffer as BenefitIcon
} from '@mui/icons-material';

const formatCurrency = (amount, currency = 'GBP') => {
  if (!amount) return null;
  
  // Handle string amounts that might include '£' or ',' already
  const cleanAmount = typeof amount === 'string' 
    ? parseFloat(amount.replace(/[£,]/g, ''))
    : amount;

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(cleanAmount);
};

const formatDate = (dateString) => {
  if (!dateString) return null;
  try{
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(new Date(dateString));
  } catch {
    return dateString;
  }
};

const SalarySection = ({ salaryInfo }) => {
  if (!salaryInfo) return null;
  
  const labels = {
    'annual_salary': 'Annual Salary',
    'day_rate': 'Daily Rate',
    'not_currently_receiving': 'Not Currently Receiving',
    'chose_not_to_share': 'Preferred Not to Share'
  };

  if (salaryInfo.type === 'chose_not_to_share') {
    return (
      <Alert severity="info" sx={{ mb: 2 }}>
        Salary information withheld
      </Alert>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <MoneyIcon sx={{ fontSize: 20, color: 'primary.main' }} />
        <Typography variant="subtitle2">{labels[salaryInfo.type]}</Typography>
      </Box>
      
      {salaryInfo.amount && (
        <Typography variant="body1">
          {formatCurrency(salaryInfo.amount, salaryInfo.currency)}
          {salaryInfo.type === 'day_rate' && ' per day'}
        </Typography>
      )}
      
      {salaryInfo.last_received && salaryInfo.type === 'not_currently_receiving' && (
        <Typography variant="body2" color="text.secondary">
          Last received: {formatDate(salaryInfo.last_received)}
        </Typography>
      )}
    </Box>
  );
};

const TotalCompRangeSection = ({ range }) => {
  if (!range) return null;
  
  return (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Total Package Range
      </Typography>
      <Typography variant="body1">
        {formatCurrency(range.min_value, range.currency)} - {formatCurrency(range.max_value, range.currency)}
      </Typography>
      {range.components && (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 1 }}>
          {range.components.map((component, index) => (
            <Chip
              key={index}
              label={component}
              size="small"
              variant="outlined"
              sx={{ height: 20 }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const BonusSection = ({ bonus }) => {
  if (!bonus) return null;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <BonusIcon sx={{ fontSize: 20, color: 'primary.main' }} />
        <Typography variant="subtitle2">Bonus Structure</Typography>
      </Box>
      
      <List dense disablePadding>
        {bonus.target_percentage && (
          <ListItem disableGutters>
            <ListItemText 
              primary="Target Bonus"
              secondary={`${bonus.target_percentage}% of base salary`}
            />
          </ListItem>
        )}
        
        {bonus.personal && (
          <ListItem disableGutters>
            <ListItemText 
              primary="Individual Component"
              secondary={
                <>
                  {bonus.personal.percentage}%
                  {bonus.personal.description && (
                    <Typography variant="body2" color="text.secondary">
                      {bonus.personal.description}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        )}

        {bonus.company && (
          <ListItem disableGutters>
            <ListItemText 
              primary="Company Component"
              secondary={
                <>
                  {bonus.company.percentage}%
                  {bonus.company.description && (
                    <Typography variant="body2" color="text.secondary">
                      {bonus.company.description}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        )}

        {bonus.typical_achievement && (
          <ListItem disableGutters>
            <ListItemText 
              primary="Typical Achievement"
              secondary={`${bonus.typical_achievement}% of target`}
            />
          </ListItem>
        )}

        {bonus.payment_timing && (
          <ListItem disableGutters>
            <ListItemText 
              primary="Payment Schedule"
              secondary={bonus.payment_timing}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

const SharesSection = ({ equity }) => {
  if (!equity) return null;

  const typeLabels = {
    'rsu': 'Restricted Share Units',
    'options': 'Share Options',
    'performance_shares': 'Performance Shares',
    'other': 'Other Share-Based Awards'
  };

  const frequencyLabels = {
    'annual': 'Annual Award',
    'one_off': 'One-off Award',
    'other': 'Other'
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <SharesIcon sx={{ fontSize: 20, color: 'primary.main' }} />
        <Typography variant="subtitle2">Share-Based Awards</Typography>
      </Box>

      {equity.types && (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mb: 1 }}>
          {equity.types.map((type, index) => (
            <Chip
              key={index}
              label={typeLabels[type] || type}
              size="small"
              variant="outlined"
              sx={{ height: 20 }}
            />
          ))}
        </Box>
      )}

      {equity.grants && (
        <List dense disablePadding>
          {equity.grants.map((grant, index) => (
            <ListItem key={index} disableGutters sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {typeLabels[grant.type]} - {frequencyLabels[grant.frequency]}
              </Typography>
              
              {grant.value && (
                <Typography variant="body2">
                  Value: {grant.value}
                </Typography>
              )}
              
              {grant.vesting_schedule && (
                <Typography variant="body2">
                  Vesting Schedule: {grant.vesting_schedule}
                </Typography>
              )}
              
              {grant.performance_conditions && (
                <Typography variant="body2" color="text.secondary">
                  Performance Conditions: {grant.performance_conditions}
                </Typography>
              )}
              
              {grant.additional_details && (
                <Typography variant="body2" color="text.secondary">
                  {grant.additional_details}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

const PensionSection = ({ pension }) => {
  if (!pension) return null;

  const typeLabels = {
    'standard_dc': 'Standard DC Scheme',
    'cash_alternative': 'Cash in Lieu of Pension',
    'executive_pension': 'Executive Pension Scheme',
    'defined_benefit': 'Final Salary Scheme',
    'none': 'No Pension Provision',
    'chose_not_to_share': 'Preferred Not to Share'
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <PensionIcon sx={{ fontSize: 20, color: 'primary.main' }} />
        <Typography variant="subtitle2">Pension Arrangements</Typography>
      </Box>

      {pension.arrangement_type === 'chose_not_to_share' ? (
        <Alert severity="info">Pension information withheld</Alert>
      ) : (
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText 
              primary="Scheme Type"
              secondary={typeLabels[pension.arrangement_type]}
            />
          </ListItem>

          {pension.contribution_structure && (
            <ListItem disableGutters>
              <ListItemText 
                primary="Contribution Structure"
                secondary={
                  <>
                    <Typography variant="body2">
                      Employer Base: {pension.contribution_structure.employer_base}
                    </Typography>
                    {pension.contribution_structure.employer_maximum && (
                      <Typography variant="body2">
                        Maximum Employer: {pension.contribution_structure.employer_maximum}
                      </Typography>
                    )}
                    {pension.contribution_structure.matching_details && (
                      <Typography variant="body2">
                        Matching Terms: {pension.contribution_structure.matching_details}
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
          )}

          {pension.employee_contribution && (
            <ListItem disableGutters>
              <ListItemText 
                primary="Your Contribution"
                secondary={pension.employee_contribution}
              />
            </ListItem>
          )}

          {pension.employer_contribution && (
            <ListItem disableGutters>
              <ListItemText 
                primary="Employer Contribution"
                secondary={pension.employer_contribution}
              />
            </ListItem>
          )}

          {pension.special_circumstances && (
            <ListItem disableGutters>
              <ListItemText 
                primary="Special Arrangements"
                secondary={pension.special_circumstances}
              />
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

const BenefitsSection = ({ benefits }) => {
  if (!benefits?.length) return null;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <BenefitIcon sx={{ fontSize: 20, color: 'primary.main' }} />
        <Typography variant="subtitle2">Additional Benefits</Typography>
      </Box>

      <List dense disablePadding>
        {benefits.map((benefit, index) => (
          <ListItem key={index} disableGutters>
            <ListItemText
              primary={benefit.type}
              secondary={
                <>
                  {benefit.value && (
                    <Typography variant="body2">
                      Value: {benefit.value}
                    </Typography>
                  )}
                  {benefit.description && (
                    <Typography variant="body2" color="text.secondary">
                      {benefit.description}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const SharingPreferencesSection = ({ preferences }) => {
  if (!preferences) return null;

  const levelLabels = {
    'full': 'Full Details Shared',
    'total_comp_only': 'Total Package Only',
    'partial': 'Partial Information',
    'none': 'Information Withheld'
  };

  if (preferences.declined_all_comp_discussion) {
    return (
      <Alert severity="info">
        Preferred not to discuss remuneration details
      </Alert>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Sharing Preferences
      </Typography>
      
      {preferences.sharing_level && (
        <Chip
          label={levelLabels[preferences.sharing_level]}
          size="small"
          color="primary"
          variant="outlined"
          sx={{ mb: 1 }}
        />
      )}
      
      {preferences.specific_restrictions && (
        <Typography variant="body2" color="text.secondary">
          {preferences.specific_restrictions}
        </Typography>
      )}
    </Box>
  );
};

const RemunerationDetailsCard = ({ remuneration }) => {
  if (!remuneration) return null;

  const { 
    salary_info,
    total_comp_range,
    bonus,
    equity,
    pension,
    additional_benefits,
    sharing_preferences
  } = remuneration;

  return (
    <Stack spacing={2} divider={<Divider />}>
      {/* Sharing Preferences Alert */}
      {sharing_preferences && (
        <SharingPreferencesSection preferences={sharing_preferences} />
      )}

      {/* Basic Salary/Total Comp */}
      {salary_info && <SalarySection salaryInfo={salary_info} />}
      {total_comp_range && <TotalCompRangeSection range={total_comp_range} />}

      {/* Bonus Structure */}
      {bonus && <BonusSection bonus={bonus} />}

      {/* Shares */}
      {equity && <SharesSection equity={equity} />}

      {/* Pension */}
      {pension && <PensionSection pension={pension} />}

      {/* Additional Benefits */}
      {additional_benefits && <BenefitsSection benefits={additional_benefits} />}
    </Stack>
  );
};

export default RemunerationDetailsCard;