import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemText,
  Box,
  Chip,
  Divider
} from '@mui/material';
import {
  Home as HomeIcon,
  Business as BusinessIcon,
  FlightTakeoff as FlightIcon,
  LocationOn as LocationIcon
} from '@mui/icons-material';

const Section = ({ label, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const Location = ({ location }) => {
  if (!location) return null;
  
  const locationParts = [
    location.city,
    location.region,
    location.country
  ].filter(Boolean);

  return (
    <Box component="span">
      <Box component="span" sx={{ typography: 'body2', color: 'text.primary'  }}>
        {locationParts.join(', ')}
      </Box>
      {location.details && (
        <Box component="span" sx={{ typography: 'body2', color: 'text.secondary', display: 'block' }}>
          {location.details}
        </Box>
      )}
    </Box>
  );
};

const WorkingPatternChip = ({ pattern }) => {
  const colors = {
    'fully_remote': 'success',
    'hybrid': 'info',
    'office_based': 'warning'
  };

  const labels = {
    'fully_remote': 'Remote',
    'hybrid': 'Hybrid',
    'office_based': 'Office-based'
  };

  return (
    <Chip
      size="small"
      label={labels[pattern] || pattern}
      color={colors[pattern] || 'default'}
      variant="outlined"
      sx={{ height: 20 }}
    />
  );
};

const CurrentArrangements = ({ current }) => {
  if (!current) return null;

  return (
    <List dense disablePadding>
      {current.home_location && (
        <ListItem disableGutters>
          <HomeIcon sx={{ fontSize: 16, mr: 1, color: 'text.secondary' }} />
          <ListItemText
            primary="Home Location"
            secondary={<Location location={current.home_location} />}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
      
      {current.working_pattern && (
        <ListItem disableGutters>
          <BusinessIcon sx={{ fontSize: 16, mr: 1, color: 'text.secondary' }} />
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box component="span" sx={{ typography: 'body2' }}>Working Pattern</Box>
                <WorkingPatternChip pattern={current.working_pattern} />
              </Box>
            }
            secondary={current.pattern_details}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {current.office_location && (
        <ListItem disableGutters>
          <LocationIcon sx={{ fontSize: 16, mr: 1, color: 'text.secondary' }} />
          <ListItemText
            primary="Office Location"
            secondary={<Location location={current.office_location} />}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {current.current_commute && (
        <ListItem disableGutters>
          <ListItemText
            primary="Current Commute"
            secondary={current.current_commute}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
    </List>
  );
};

const TravelRequirements = ({ travel }) => {
  if (!travel?.domestic_frequency && !travel?.international_frequency) return null;

  return (
    <List dense disablePadding>
      {travel.domestic_frequency && (
        <ListItem disableGutters>
          <ListItemText
            primary="Domestic Travel"
            secondary={travel.domestic_frequency}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
      {travel.international_frequency && (
        <ListItem disableGutters>
          <FlightIcon sx={{ fontSize: 16, mr: 1, color: 'text.secondary' }} />
          <ListItemText
            primary="International Travel"
            secondary={travel.international_frequency}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
    </List>
  );
};

const PreferencesList = ({ preferences }) => {
  if (!preferences) return null;

  return (
    <List dense disablePadding>
      {preferences.preferred_arrangements?.length > 0 && (
        <ListItem disableGutters>
          <ListItemText
            primary="Preferred Working Arrangements"
            secondary={
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
                {preferences.preferred_arrangements.map((arrangement, index) => (
                  <WorkingPatternChip key={index} pattern={arrangement} />
                ))}
              </Box>
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {preferences.preferred_locations?.length > 0 && (
        <ListItem disableGutters>
          <ListItemText
            primary="Preferred Locations"
            secondary={
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
                {preferences.preferred_locations.map((location, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={location}
                    variant="outlined"
                    sx={{ height: 20 }}
                  />
                ))}
              </Box>
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {preferences.max_commute_willing && (
        <ListItem disableGutters>
          <ListItemText
            primary="Maximum Commute"
            secondary={preferences.max_commute_willing}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {preferences.relocation_considerations && (
        <ListItem disableGutters>
          <ListItemText
            primary="Relocation Considerations"
            secondary={preferences.relocation_considerations}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
    </List>
  );
};

const RedLinesList = ({ redLines }) => {
  if (!redLines) return null;

  return (
    <List dense disablePadding>
      {redLines.excluded_locations?.length > 0 && (
        <ListItem disableGutters>
          <ListItemText
            primary="Excluded Locations"
            secondary={
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
                {redLines.excluded_locations.map((location, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={location}
                    color="error"
                    variant="outlined"
                    sx={{ height: 20 }}
                  />
                ))}
              </Box>
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {redLines.excluded_arrangements?.length > 0 && (
        <ListItem disableGutters>
          <ListItemText
            primary="Excluded Working Arrangements"
            secondary={
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 0.5 }}>
                {redLines.excluded_arrangements.map((arrangement, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={arrangement}
                    color="error"
                    variant="outlined"
                    sx={{ height: 20 }}
                  />
                ))}
              </Box>
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {redLines.max_travel_domestic && (
        <ListItem disableGutters>
          <ListItemText
            primary="Maximum Domestic Travel"
            secondary={redLines.max_travel_domestic}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {redLines.max_travel_international && (
        <ListItem disableGutters>
          <ListItemText
            primary="Maximum International Travel"
            secondary={redLines.max_travel_international}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}

      {redLines.other_restrictions && (
        <ListItem disableGutters>
          <ListItemText
            primary="Other Restrictions"
            secondary={redLines.other_restrictions}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
    </List>
  );
};

const LocationAndTravel = ({ details }) => {
  if (!details) return null;

  const { 
    current,
    travel,
    preferences,
    red_lines,
    notes
  } = details;

  return (
    <Stack spacing={2} divider={<Divider />}>
      {/* Current Work Arrangements */}
      {current && (
        <Section label="Current Work Setup">
          <CurrentArrangements current={current} />
        </Section>
      )}

      {/* Travel Requirements */}
      {travel && (
        <Section label="Travel Requirements">
          <TravelRequirements travel={travel} />
        </Section>
      )}

      {/* Location Preferences */}
      {preferences && (
        <Section label="Location Preferences">
          <PreferencesList preferences={preferences} />
        </Section>
      )}

      {/* Red Lines */}
      {red_lines && (
        <Section label="Restrictions">
          <RedLinesList redLines={red_lines} />
        </Section>
      )}

      {/* Additional Notes */}
      {notes && (
        <Box component="div" sx={{ typography: 'body2', color: 'text.secondary' }}>
          {notes}
        </Box>
      )}
    </Stack>
  );
};

export default LocationAndTravel;