import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStatus } from '../../hooks/useAuthStatus';

export const PublicRoute = ({ children }) => {
  const { isLoading, hasValidAccount, shouldWaitlist } = useAuthStatus();

  // Stay in loading state until we have definitive information
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Only redirect when we have definitive account status
  if (hasValidAccount) {
    return <Navigate to="/onboarding" replace />;
  }

  if (shouldWaitlist) {
    return <Navigate to="/waitlist" replace />;
  }

  return children;
};