import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { identifyUser } from '../../analytics/identify';

export const ProtectedRoute = ({ children, requiresAccount = true }) => {
  const { isLoading, isAuthenticated, hasValidAccount, user, hasAccount } = useAuthStatus();
  const location = useLocation();

  useEffect(() => {
    if (user && hasAccount !== undefined) {
      identifyUser(user, hasAccount);
    }
  }, [user, hasAccount]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (requiresAccount && !hasValidAccount) {
    return <Navigate to="/waitlist" replace />;
  }

  return children;
};