import { useMemo } from 'react';
import CommitmentsList from './CommitmentsList';
import CurrentRoleSatisfaction from './CurrentRoleSatisfaction';
import RoleScope from './RoleScope';
import RemunerationDetailsCard from './RemunerationDetailsCard';
import LocationAndTravel from './LocationAndTravel';
import OrganisationPreferences from './OrganisationPreferences';
import ContactPreferences from './ContactPreferences';
import DataControl from './DataControl';
import Recommendations from './Recommendations';
import JSONCard from './JSONCard';

const PROFILE_SECTIONS = [
  {
    component: CommitmentsList,
    propKey: 'commitments',
    dataKey: 'current_commitments',
    title: "Employment",
    stage: 'employment',
  },
  {
    component: CurrentRoleSatisfaction,
    propKey: 'details',
    dataKey: 'current_role_satisfaction',
    title: "Satisfaction",
    stage: 'satisfaction',
  },
  {
    component: RoleScope,
    propKey: 'details',
    dataKey: 'current_role_scope',
    title: "Role scale & scope",
    stage: 'role scale & scope',
  },
  {
    component: RemunerationDetailsCard,
    propKey: 'remuneration',
    dataKey: 'remuneration_details',
    title: "Remuneration Details",
    stage: 'remuneration',
  },
  {
    component: LocationAndTravel,
    propKey: 'details',
    dataKey: 'location_and_travel',
    title: "Location and Travel",
    stage: 'location and travel',
  },
  {
    component: OrganisationPreferences,
    propKey: 'details',
    dataKey: 'organisation_preferences',
    title: "Organisation Preferences",
    stage: 'organisation preferences',
  },
  {
    component: ContactPreferences,
    propKey: 'details',
    dataKey: 'contact_preferences',
    title: "Contact Preferences",
    stage: 'contact preferences',
  },
  {
    component: DataControl,
    propKey: 'details',
    dataKey: 'data_control',
    title: "Data Control",
    stage: 'data control',
  },
  {
    component: Recommendations,
    propKey: 'recommendations',
    dataKey: 'recommendations',
    title: "Recommendations",
    stage: 'recommendations'
  },
  {
    component: JSONCard,
    propKey: 'aspirations',
    dataKey: 'aspirations',
    title: "Aspirations",
    stage: 'triage',
  }
];

const hasValidData = (data) => {
  if (!data) return false;
  
  if (Array.isArray(data)) {
    return data.length > 0;
  }
  
  if (typeof data === 'object') {
    return Object.values(data).some(value => value != null);
  }
  
  return data != null;
};

const createSection = (sectionConfig, profileData) => ({
  ...sectionConfig,
  props: { [sectionConfig.propKey]: profileData[sectionConfig.dataKey] }
});

export const useProfileSections = (profile) => {
  return useMemo(() => {
    if (!profile) return [];

    return PROFILE_SECTIONS
      .filter(section => hasValidData(profile[section.dataKey]))
      .map(section => createSection(section, profile));
  }, [profile]);
};