import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import { AttachMoney, AccessTime } from '@mui/icons-material';

const CompensationBenefits = () => {
  return (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <AttachMoney sx={{ mr: 1 }} /> Compensation and Benefits
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Total Package: approximately £1 million per annum
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <AccessTime sx={{ mr: 1 }} />
        <Typography variant="body1">
          Notice Period: 12 months
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" gutterBottom>Breakdown:</Typography>
      <List dense>
        <ListItem>
          <ListItemText 
            primary="Base Salary" 
            secondary="£375,000 per annum" 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Annual Bonus" 
            secondary="Up to 75% of base salary (achieved 68% in 2022, 72% in 2021)" 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Equity" 
            secondary="0.75% of company shares (vesting over 4 years, with 25% vested to date)" 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Long-Term Incentive Plan" 
            secondary="Additional 0.25% in performance shares based on 3-year revenue growth targets" 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Pension" 
            secondary="12% employer contribution" 
          />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" gutterBottom>Other Benefits:</Typography>
      <List dense>
        <ListItem>
          <ListItemText primary="Comprehensive international health insurance for family" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Annual wellness stipend of £5,000" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Flexible working arrangements" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Education allowance for children (£15,000 per child per year)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Company car allowance (£1,000 per month)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Annual executive health check" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Professional development budget of £25,000 per year" />
        </ListItem>
      </List>
    </Paper>
  );
};

export default CompensationBenefits;