import React from 'react';
import { Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Logo = ({ handleDrawerToggle, isMobile }) => {
  return (
    <Toolbar>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="/logo.svg" alt="Nexco.ai" style={{ height: 40, marginRight: 10 }} />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  );
};

export default Logo;