import { Box } from '@mui/material';

const JSONCard = ({ details }) => {
  const formatJSON = (data) => {
    try {
      if (typeof data === 'string') {
        try {
          return JSON.stringify(JSON.parse(data), null, 2);
        } catch {
          return data;
        }
      }
      return JSON.stringify(data, null, 2);
    } catch {
      return 'Invalid JSON data';
    }
  };

  return (
    <Box
      component="pre"
      sx={{
        bgcolor: 'grey.50',
        p: 2,
        borderRadius: 1,
        overflowX: 'auto',
        margin: 0,
        maxWidth: '100%',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        '& code': {
          fontSize: '0.875rem',
          fontFamily: 'monospace',
        }
      }}
    >
      <code>
        {formatJSON(details)}
      </code>
    </Box>
  );
};

export default JSONCard;