import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Avatar, 
  Typography, 
  Menu, 
  MenuItem, 
  ListItemButton
} from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import useAPIWithAuth from '../../hooks/useAPIWithAuth';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, user } = useAuth0();
  const api = useAPIWithAuth();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetOnboarding = async () => {
    await api.post('/chat/onboarding');
    navigate('/onboarding');
  };
  const handleResetAspirations = async () => {
    await api.post('/chat/aspirations');
    navigate('/aspirations');
  };

  return (
    <Box sx={{ mt: 'auto' }}>
      <ListItemButton onClick={handleMenu}>
        <Avatar 
          src={user?.picture} 
          alt={user?.name || 'User avatar'}
          sx={{ mr: 2 }}
          imgProps={{ referrerPolicy: "no-referrer" }}
        />
        <Box sx={{ minWidth: 0 }}>
          <Typography variant="body2" noWrap>
            {user?.name || 'User'}
          </Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {user?.email?.toLowerCase()}
          </Typography>
        </Box>
      </ListItemButton>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleResetOnboarding}>
          Revisit onboarding
        </MenuItem>
        <MenuItem onClick={handleResetAspirations}>
          Revisit aspirations
        </MenuItem>
        <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
          Log Out
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AccountMenu;