import React from 'react';
import { Drawer, Toolbar } from '@mui/material';
import Logo from './Logo';
import AccountMenu from './AccountMenu';

const Sidebar = ({ 
  mobileOpen, 
  handleDrawerToggle, 
  isMobile, 
  drawerWidth,
  content: DrawerContent, // Component to render as content
  showLogo = true,       // Optional flags to control visibility
  showAccountMenu = true 
}) => {
  const drawerContent = (
    <div>
      <Toolbar />
      <DrawerContent />
    </div>
  );

  return (
    <>
      {/* Mobile Drawer */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {showAccountMenu && (
          <AccountMenu handleDrawerToggle={handleDrawerToggle} isMobile={isMobile} />
        )}
        {drawerContent}
      </Drawer>

      {/* Desktop Drawer */}
      <Drawer
        variant="permanent"
        PaperProps={{
          style: {
            borderRight: 'none',
          },
        }}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {showLogo && <Logo handleDrawerToggle={handleDrawerToggle} isMobile={isMobile} />}
        {drawerContent}
        {showAccountMenu && (
          <AccountMenu handleDrawerToggle={handleDrawerToggle} isMobile={isMobile} />
        )}
      </Drawer>
    </>
  );
};

export default Sidebar;