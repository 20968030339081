import * as Sentry from "@sentry/react";
import { clarity } from 'react-microsoft-clarity';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

export const useAnalytics = () => {
  
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: "https://afd85e2c0fff6c7b1c7fb3334e6cc8a5@o4508145798414336.ingest.de.sentry.io/4508145804181584",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/app\.nexco\.ai/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        whitelistUrls: [
          'app.nexco.ai', // Ignore clarity etc. errors
        ]
      });

      clarity.init('oplnhswh4l');
      ReactGA.initialize('G-9N0GPGN7BC');
      amplitude.init('d72eae8a68635506f857b508646ccbd8', {
        serverZone: 'EU',
        defaultTracking: {
          sessions: true,
          pageViews: true,
          formInteractions: true,
          fileDownloads: true
        }
      });
    }
  }, []);
};