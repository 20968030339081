import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { People, Pets, SportsHandball, Restaurant } from '@mui/icons-material';

const FamilyAndInterests = () => (
  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
    <Typography variant="h6" gutterBottom>Family and Interests</Typography>

    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 2 }}>
      <People sx={{ mr: 1 }} /> Family
    </Typography>
    <List dense>
      <ListItem>
        <ListItemText primary="Married to Michael" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Three children" secondary="Ages 8, 12, and 14" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Lives in Surrey" secondary="Moved to be close to her mother, supports mother with bi-weekly visits" />
      </ListItem>
      <ListItem>
        <ListItemText primary="" />
      </ListItem>
    </List>

    <Divider sx={{ my: 2 }} />

    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Pets sx={{ mr: 1 }} /> Pets
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Has a dog named Sally
    </Typography>

    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <SportsHandball sx={{ mr: 1 }} /> Sports
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Keen triathlete
    </Typography>

    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Restaurant sx={{ mr: 1 }} /> Hobbies
    </Typography>
    <Typography variant="body2">
      Enjoys cooking
    </Typography>
  </Paper>
);

export default FamilyAndInterests;