// GlobalStateContext.js
import React, { createContext, useReducer } from 'react';

const initialState = {
  messageCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INCREMENT_MESSAGE_COUNT':
      return {
        ...state,
        messageCount: state.messageCount + 1,
      };
    default:
      return state;
  }
};

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
