import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText } from '@mui/material';

const CareerAspirations = () => (
  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
    <Typography variant="h6" gutterBottom>Career Aspirations</Typography>
    
    <Typography variant="subtitle1" gutterBottom>Short-term (1-2 years):</Typography>
    <List dense>
      <ListItem>
        <ListItemText primary="Lead TechInnovate's expansion into the AI-driven marketing solutions market" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Spearhead a major digital transformation initiative across all customer touchpoints" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Achieve 30% year-on-year growth in marketing-attributed revenue" />
      </ListItem>
    </List>

    <Typography variant="subtitle1" gutterBottom>Medium-term (3-5 years):</Typography>
    <List dense>
      <ListItem>
        <ListItemText primary="Transition to a Chief Digital Officer or Chief Customer Officer role" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Gain experience in a pre-IPO company or guide a company through the IPO process" />
      </ListItem>
    </List>

    <Typography variant="subtitle1" gutterBottom>Long-term (5-10 years):</Typography>
    <List dense>
      <ListItem>
        <ListItemText primary="Secure a position on the board of a FTSE 100 company" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Potentially transition to a CEO role in a mid-sized tech company" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Establish a mentorship program for women in tech leadership roles" />
      </ListItem>
    </List>
  </Paper>
);

export default CareerAspirations;