import { useAuth0 } from '@auth0/auth0-react';
import useHasAccount from './useHasAccount';

export const useAuthStatus = () => {
  const { isAuthenticated, isLoading: authLoading, user } = useAuth0();
  const { hasAccount } = useHasAccount(isAuthenticated);

  // Consider the state as loading until we have definitive account status
  const isLoading = authLoading || (isAuthenticated && hasAccount === undefined);
  
  // Only set these when we have complete information
  const hasValidAccount = isAuthenticated && hasAccount === "ready";
  const shouldWaitlist = isAuthenticated && hasAccount !== undefined && hasAccount !== "ready";

  return {
    isLoading,
    isAuthenticated,
    hasValidAccount,
    shouldWaitlist,
    user,
    hasAccount
  };
};