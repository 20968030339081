import React from 'react';
import { Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { ThumbUp, ThumbDown, Star } from '@mui/icons-material';

const RoleSatisfaction = () => (
  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
    <Typography variant="h6" gutterBottom>Role Satisfaction</Typography>
    
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Star sx={{ color: 'gold', mr: 1 }} />
      <Typography variant="body1">
        Current Satisfaction: <strong>8.5/10</strong>
      </Typography>
    </Box>

    <Typography variant="body2" paragraph>
      Sarah expresses high satisfaction with her current role, but is open to new challenges that align with her medium- and long-term aspirations.
    </Typography>

    <Divider sx={{ my: 2 }} />

    <Typography variant="subtitle1" gutterBottom>Likes:</Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <ThumbUp color="success" />
        </ListItemIcon>
        <ListItemText primary="Challenging and dynamic work environment that constantly pushes her to innovate" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbUp color="success" />
        </ListItemIcon>
        <ListItemText primary="Significant opportunity to drive company strategy and influence product development" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbUp color="success" />
        </ListItemIcon>
        <ListItemText primary="Excellent team dynamics" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbUp color="success" />
        </ListItemIcon> 
        <ListItemText primary="Feels well remunerated" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbUp color="success" />
        </ListItemIcon>
        <ListItemText primary="Appreciates the company's commitment to work-life balance and personal development" />
      </ListItem>
    </List>

    <Divider sx={{ my: 2 }} />

    <Typography variant="subtitle1" gutterBottom>Dislikes:</Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <ThumbDown color="error" />
        </ListItemIcon>
        <ListItemText primary="Culture in Head Office can be aggressive" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbDown color="error" />
        </ListItemIcon>
        <ListItemText primary="Limited influence on overall business strategy, particularly in M&A decisions" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbDown color="error" />
        </ListItemIcon>
        <ListItemText primary="Limited development of skills in AI and machine learning applications in marketing" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ThumbDown color="error" />
        </ListItemIcon>
        <ListItemText primary="Some misalignment with personal values, especially in terms of innovation, sustainability, and diversity" />
      </ListItem>
    </List>
  </Paper>
);

export default RoleSatisfaction;