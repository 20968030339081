import React from 'react';
import { Typography, List, ListItem, ListItemText, Chip } from '@mui/material';

const formatDate = (dateString) => {
  if (!dateString) return '-';
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('default', { month: 'short', year: 'numeric' });
  } catch {
    return '-';
  }
};

const CommitmentsList = ({ commitments }) => {
  if (!commitments?.length) return null;

  return (
    <List disablePadding>
      {commitments.map((commitment, index) => (
        <ListItem
          key={`${commitment.organisation}-${index}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&:not(:last-child)': { mb: 2 }
          }}
          disableGutters
        >
          <ListItemText
            primary={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                {commitment.organisation}
                {commitment.is_primary && (
                  <Chip size="small" label="Primary" variant="outlined" />
                )}
              </div>
            }
            secondary={
              <>
                <Typography variant="body2" component="span" color="text.primary" sx={{ display: 'block' }}>
                  {commitment.role}
                </Typography>
                {commitment.status && (
                  <Typography variant="body2" component="span" sx={{ display: 'block' }}>
                    {commitment.status} · {commitment.commitment}
                  </Typography>
                )}
                {commitment.from_date && (
                  <Typography variant="body2" component="span" sx={{ display: 'block' }}>
                    Since {formatDate(commitment.from_date)}
                  </Typography>
                )}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CommitmentsList;