import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useAnalytics } from './analytics/useAnalytics';
import { usePageTracking } from './analytics/tracking';
import { ProtectedRoute } from './components/routing/ProtectedRoute';
import { PublicRoute } from './components/routing/PublicRoute';
import { InviteRoute } from './components/routing/InviteRoute';
import ChatWithProgress from './components/chat/ChatWithProgress';
import LoggedOutHome from './components/LoggedOutHome';
import Introduction from './components/introduction/Introduction';
import theme from './theme';
import Waitlist from './components/Waitlist';
import ExecutiveProfile from './components/executive_profile/ExecutiveProfile';
import ProfileWithSidebar from './components/profile/ProfileWithSidebar';

const MainContent = () => {
  usePageTracking();
  useAnalytics();

  return (
    <Routes>
      {/* Public-only routes - for non-authenticated users */}
      <Route path="/" element={
        <PublicRoute>
          <LoggedOutHome />
        </PublicRoute>
      } />

      <Route path="/introduction" element={
        <InviteRoute>
          <Introduction />
        </InviteRoute>
      } />

      {/* Protected routes - for authenticated users */}
      <Route path="/onboarding" element={
        <ProtectedRoute>
          <ChatWithProgress conversationType="onboarding"/>
        </ProtectedRoute>
      } />
      
      <Route path="/aspirations" element={
        <ProtectedRoute>
          <ChatWithProgress conversationType="aspirations" />
        </ProtectedRoute>
      } />

      <Route path="/profile" element={
        <ProtectedRoute>
          <ProfileWithSidebar />
        </ProtectedRoute>
      } />

      <Route path="/sample-profile" element={
        <PublicRoute>
          <ExecutiveProfile />
        </PublicRoute>
      } />

      <Route path="/waitlist" element={
        <ProtectedRoute requiresAccount={false}>
          <Waitlist />
        </ProtectedRoute>
      } />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="nexco.uk.auth0.com"
    clientId="2NF2m4jVgqxQmXBfpgSUxCbyvvtBLlZI"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://api.nextco.ai",
      scope: "openid profile email offline_access"
    }}
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainContent />
      </ThemeProvider>
    </BrowserRouter>
  </Auth0Provider>
);