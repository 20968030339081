import React from 'react';
import { 
  Typography, 
  Stack,
  Box,
  Chip
} from '@mui/material';
import {
  Block as BlockIcon,
} from '@mui/icons-material';

const Section = ({ label, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Typography 
        variant="body2" 
        color="text.secondary" 
        sx={{ mb: 0.5 }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const OrganisationPreferences = ({ details }) => {
  if (!details) return null;

  const { overview, absolute_exclusions, notes } = details;

  return (
    <Stack spacing={2}>
      {/* Overview Section */}
      {overview && (
        <Typography variant="body2">
          {overview}
        </Typography>
      )}

      {/* Absolute Exclusions */}
      {absolute_exclusions?.length > 0 && (
        <Section label="Will not consider">
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {absolute_exclusions.map((exclusion, index) => (
              <Chip
                key={index}
                label={exclusion}
                size="small"
                color="error"
                variant="outlined"
                icon={<BlockIcon />}
                sx={{ height: 20 }}
              />
            ))}
          </Box>
        </Section>
      )}

      {/* Additional Notes */}
      {notes && (
        <Typography 
          variant="body2" 
          color="text.secondary"
        >
          {notes}
        </Typography>
      )}
    </Stack>
  );
};

export default OrganisationPreferences;