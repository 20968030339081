import React from 'react';
import { Paper, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Work, People, AttachMoney, Person } from '@mui/icons-material';

const QuickFacts = () => (
  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
    <Typography variant="h6" gutterBottom>Scale and Scope</Typography>
    
    <List dense>
      <ListItem>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary="Reports directly to" secondary="CEO, Michael Chen" />
      </ListItem>
      
      <ListItem>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Team Size" secondary="78 professionals across 5 continents" />
      </ListItem>
      
      <ListItem>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary="Budget Responsibility" secondary="$137 million annually" />
      </ListItem>
      
      <ListItem>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Key member of" secondary="7-person executive leadership team" />
      </ListItem>
    </List>

    <Typography variant="subtitle1" gutterBottom>Direct Reports:</Typography>
    <List dense>
      <ListItem>
        <ListItemText primary="Head of Brand Strategy and Creative (UK-based)" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Director of Digital Marketing and E-commerce (US-based)" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Head of Product Marketing (Singapore-based)" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Director of Marketing Analytics and Insights (Germany-based)" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Head of Content and Social Media Strategy (Australia-based)" />
      </ListItem>
    </List>
  </Paper>
);

export default QuickFacts;