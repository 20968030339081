export const ConnectionStatus = ({ isConnected }) => (
    <div 
      style={{ 
        position: 'absolute', 
        top: '10px', 
        right: '10px', 
        color: isConnected ? 'green' : 'red' 
      }}
    >
      ●
    </div>
  );