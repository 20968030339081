import React from 'react';
import { 
  Typography, 
  Box,
  Stack,
  Rating,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const BulletList = ({ items }) => {
  if (!items?.length) return null;
  return (
    <List dense disablePadding>
      {items.map((item, index) => (
        <ListItem key={index} disableGutters>
          <ListItemText 
            primary={item}
            primaryTypographyProps={{ 
              variant: 'body2'
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

const SectionLabel = ({ children }) => (
  <Typography 
    variant="body2" 
    color="text.secondary"
    sx={{ mb: 0.5 }}
  >
    {children}
  </Typography>
);

const CurrentRoleSatisfaction = ({ details }) => {
  if (!details) return null;

  const { satisfaction_score, primary_explanation, detailed_context } = details;

  if (details.declined_to_share?.declined_score && 
      details.declined_to_share?.declined_explanation) {
    return (
      <Typography color="text.secondary" variant="body2">
        Declined to share satisfaction details
      </Typography>
    );
  }

  return (
    <Stack spacing={2}>
      {/* Score and Primary Explanation */}
      {!details.declined_to_share?.declined_score && (
        <Box>
          <Rating 
            value={satisfaction_score || 0}
            max={10}
            readOnly
            size="small"
            sx={{ mb: 1 }}
          />
          <Typography variant="body2">
            {primary_explanation}
          </Typography>
        </Box>
      )}

      {/* Detailed Context */}
      {detailed_context && (
        <>
          {detailed_context.key_factors && (
            <Box>
              <SectionLabel>Key Factors</SectionLabel>
              <BulletList items={detailed_context.key_factors} />
            </Box>
          )}

          {detailed_context.duration && (
            <Box>
              <SectionLabel>Duration</SectionLabel>
              <Typography variant="body2">
                {detailed_context.duration}
              </Typography>
            </Box>
          )}

          {/* Low Satisfaction: Improvement Actions */}
          {detailed_context.improvement_actions && (
            <Box>
              <SectionLabel>Actions Being Taken</SectionLabel>
              <BulletList items={detailed_context.improvement_actions.steps_taken} />
              {detailed_context.improvement_actions.desired_resolution && (
                <Box sx={{ mt: 1.5 }}>
                  <SectionLabel>Desired Outcome</SectionLabel>
                  <Typography variant="body2">
                    {detailed_context.improvement_actions.desired_resolution}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {/* High Satisfaction: Opportunity Openness */}
          {detailed_context.opportunity_openness && (
            <Box>
              <SectionLabel>Openness to Opportunities</SectionLabel>
              <Typography variant="body2">
                {detailed_context.opportunity_openness.would_consider ? 
                  'Would consider new opportunities' : 
                  'Not currently interested in new opportunities'}
              </Typography>
              {detailed_context.opportunity_openness.compelling_factors && (
                <Box sx={{ mt: 1.5 }}>
                  <SectionLabel>Would Consider For</SectionLabel>
                  <BulletList items={detailed_context.opportunity_openness.compelling_factors} />
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default CurrentRoleSatisfaction;