import React, { useRef, useState, useEffect, useContext } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAPIWithAuth from '../../hooks/useAPIWithAuth';
import { ProfileContainer } from './ProfileContainer';
import { useProfile } from './useProfile';
import { useProfileSections } from './useProfileSections';
import { GlobalStateContext } from '../../global_state_context';

const ProfileAccordion = ({ conversationType }) => {
  const [expanded, setExpanded] = useState(false);
  const api = useAPIWithAuth();
  const fetchProfile = React.useCallback(async () => {
    const response = await api.get('/profile/'+conversationType);
    return response.data;
  }, [api, conversationType]);
  const { state } = useContext(GlobalStateContext);

  const { profile, error, setProfile } = useProfile(fetchProfile);
  useEffect(() => {
    async function fetchData() {
      const newProfileData = await fetchProfile();
      setProfile(newProfileData);
      setExpanded(newProfileData.stage);
    }
    fetchData();
  }, [state.messageCount, fetchProfile, setProfile]);
  
  const activeCardRef = useRef(null);
  
  const sections = useProfileSections(profile);

  React.useEffect(() => {
    if (profile && activeCardRef.current) {
      activeCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [expanded, profile]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderProfileData = () => {
    return (
      <Paper elevation={1}>
        {sections.map((section) => {
          const Component = section.component;
          return (
            <Accordion
              key={section.stage}
              expanded={expanded === section.stage}
              onChange={handleChange(section.stage)}
              ref={expanded === section.stage ? activeCardRef : null}
              disableGutters
              sx={{
                '&:before': {
                  display: 'none',
                },
                bgcolor: 'common.white',
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                '&:first-of-type': {
                  borderTop: 'none'
                }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Component {...section.props} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Paper>
    );
  };

  return (
    <ProfileContainer loading={!profile} error={error}>
      {sections.length > 0 && renderProfileData()}
    </ProfileContainer>
  );
};

export default ProfileAccordion;