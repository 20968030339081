import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Chat, Phone, HelpOutline } from '@mui/icons-material';

const ExecutiveProfileHeader = () => {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      mb: 3,
      pt: 2,
      pb: 2
    }}>
      <Box sx={{ flexGrow: 1, mr: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>Sarah Johnson</Typography>
        <Typography variant="subtitle1" sx={{ fontSize: '1.1rem', mb: 0.5 }}>Chief Marketing Officer (CMO) at TechInnovate Inc.</Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>Work office: London, Home: Surrey, Open to international relocation</Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>Package: ~£1m, Base: £375k, Notice: 12 months</Typography>
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 1,
        width: '33%',
        minWidth: '220px',
      }}>
        <Button 
          variant="contained" 
          startIcon={<Chat />} 
          fullWidth
          sx={{ 
            backgroundColor: '#FF7F50',
            color: 'white',
            '&:hover': {
              backgroundColor: '#FF6347',
            },
            borderRadius: '20px',
            textTransform: 'none',
            fontSize: '0.9rem',
          }}
        >
          Start Chatting
        </Button>
        <Button 
          variant="outlined" 
          startIcon={<Phone />} 
          fullWidth
          sx={{ 
            borderColor: '#FF7F50',
            color: '#FF7F50',
            '&:hover': {
              borderColor: '#FF6347',
              color: '#FF6347',
            },
            borderRadius: '20px',
            textTransform: 'none',
            fontSize: '0.9rem',
          }}
        >
          Arrange a Call
        </Button>
        <Button 
          variant="outlined" 
          startIcon={<HelpOutline />} 
          fullWidth
          sx={{ 
            borderColor: '#8A2BE2',
            color: '#8A2BE2',
            '&:hover': {
              borderColor: '#9370DB',
              color: '#9370DB',
            },
            borderRadius: '20px',
            textTransform: 'none',
            fontSize: '0.9rem',
          }}
        >
          Ask for Missing Info
        </Button>
      </Box>
    </Box>
  );
};

export default ExecutiveProfileHeader;