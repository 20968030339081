import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from '@mui/material';

const LoginButton = ({ redirectUri, buttonText }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      redirect_uri: redirectUri
    });
  };

  return (
    <Button 
      variant="contained"
      color="primary"
      style={{ marginBottom: '1rem', width: '80%', maxWidth: '300px' }}
      onClick={handleLogin}>
      {buttonText}
    </Button>
  );
};

export default LoginButton;
