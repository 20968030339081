import React, { useState } from 'react';
import { CssBaseline, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../sidebar/Sidebar';
import { GlobalStateProvider } from '../../global_state_context';
import ChatAndArtifactsContainer from './ChatAndArtifactsContainer';
import { ChatContainer } from './ChatContainer';
import ProfileAccordion from '../profile/ProfileAccordion';
import ProgressTracker from './ProgressTracker';

const ChatWithProgress = ({ conversationType }) => {  // Properly destructure the prop
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Create a curried ProgressTracker component with conversationType
  const ProgressTrackerWithType = (props) => (
    <ProgressTracker
      {...props}
      conversationType={conversationType}
    />
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <GlobalStateProvider>
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          drawerWidth={drawerWidth}
          content={ProgressTrackerWithType}  // Pass the curried component
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <ChatAndArtifactsContainer
            ChatComponent={(props) => (
              <ChatContainer
                websocketUrl={`/api/chat/${conversationType}`}  // Include conversationType in websocket URL
                requireAuthentication={true}
                {...props}
              />
            )}
            ProfileComponent={(props) => (
              <ProfileAccordion
                {...props}
                conversationType={conversationType}  // Pass conversationType to ProfileAccordion
              />
            )}
          />
        </Box>
      </GlobalStateProvider>
    </Box>
  );
};

export default ChatWithProgress;