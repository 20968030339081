import React, { useEffect, useContext, useState } from 'react';
import useAPIWithAuth from '../../hooks/useAPIWithAuth';
import { useNavigate } from 'react-router-dom';
import Progress from './Progress';
import { GlobalStateContext } from '../../global_state_context';

const ProgressTracker = ({ conversationType }) => {
  const [summary, setSummary] = useState([]);
  const navigate = useNavigate();
  const api = useAPIWithAuth();
  const { state } = useContext(GlobalStateContext);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get('/chat/' + conversationType);
      if (response.data['done'] === true) {
        navigate('/profile');
      }
      setSummary(Object.entries(response.data));
    }

    // Initial fetch
    fetchData();

  }, [api, navigate, state.messageCount, conversationType]);

  return <Progress summary={summary} />;
};

export default ProgressTracker;