import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/TaskAltRounded';
import EllipsesIcon from '@mui/icons-material/MoreHorizTwoTone';
import EditingIcon from '@mui/icons-material/AppRegistrationTwoTone';

const Progress = ({ summary = [] }) => {
  // Find the index of the last true value
  const lastTrueIndex = [...summary].reverse().findIndex(([_, value]) => value);
  const lastTruePosition = lastTrueIndex === -1 ? -1 : summary.length - 1 - lastTrueIndex;

  return (
    <List>
      {summary.length > 0 ? (
        summary.map(([key, value], index) => (
          <ListItem key={index} className="flex items-center justify-between">
            <ListItemText
              primary={`${key.charAt(0).toUpperCase()}${key.slice(1)}`}
            />
            <ListItemIcon>
              {value && index === lastTruePosition ? (
                <EditingIcon color="primary" />
              ) : value ? (
                <CheckIcon color="success" />
              ) : (
                <EllipsesIcon color="action" />
              )}
            </ListItemIcon>
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No progress data available..." />
        </ListItem>
      )}
    </List>
  );
};

export default Progress;