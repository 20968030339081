import { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const ChatLayout = ({ children, error, onErrorClose }) => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
    }}
  >
    <div
      style={{
        flex: 1,
        minHeight: 0,
        display: 'flex',
        gap: '24px',
      }}
    >
      {children}
    </div>
    <Snackbar open={!!error} autoHideDuration={6000} onClose={onErrorClose}>
      <Alert onClose={onErrorClose} severity="error" sx={{ width: '100%' }}>
        {error}
      </Alert>
    </Snackbar>
  </div>
);

const ChatSection = ({ children }) => (
  <div
    style={{
      flex: '0 0 66.666%',
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      '@media (maxWidth: 900px)': {
        flex: '1 1 100%'
      }
    }}
  >
    {children}
  </div>
);

const SidebarSection = ({ children }) => (
  <div
    style={{
      flex: '0 0 33.333%',
      minHeight: 0,
      '@media (maxWidth: 900px)': {
        display: 'none'
      }
    }}
  >
    {children}
  </div>
);

const ChatAndArtifactsContainer = ({ 
  ChatComponent,
  ProfileComponent = null,
  initialError = null
}) => {
  const [containerError, setContainerError] = useState(initialError);

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setContainerError(null);
  };

  return (
    <ChatLayout error={containerError} onErrorClose={handleErrorClose}>
      <ChatSection>
        <ChatComponent 
          onError={(error) => setContainerError(error)} 
        />
      </ChatSection>
      {ProfileComponent && (
        <SidebarSection>
          <ProfileComponent />
        </SidebarSection>
      )}
    </ChatLayout>
  );
};

export default ChatAndArtifactsContainer;