import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText } from '@mui/material';
import { School } from '@mui/icons-material';

const Education = () => (
  <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <School sx={{ mr: 1 }} /> Education
    </Typography>
    <List dense>
      <ListItem>
        <ListItemText 
          primary="MBA, Harvard Business School"
          secondary="2008 - Graduated with high distinction"
        />
      </ListItem>
      <ListItem>
        <ListItemText 
          primary="BSc in Computer Science, University of Oxford"
          secondary="2003 - First Class Honours"
        />
      </ListItem>
    </List>
  </Paper>
);

export default Education;