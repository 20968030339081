import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Work } from '@mui/icons-material';

const Experience = () => (
  <Paper elevation={1} sx={{ p: 2, mb: 3 }}>
    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Work sx={{ mr: 1 }} /> Professional Experience
    </Typography>
    <List dense>
      <ListItem>
        <ListItemText 
          primary="TechInnovate Inc."
          secondary="Chief Marketing Officer (CMO) | June 2020 - Present"
        />
      </ListItem>
      <ListItem>
        <ListItemText 
          primary="InnoSoft Solutions"
          secondary="VP of Global Marketing | 2015 - 2020"
        />
      </ListItem>
      <ListItem>
        <ListItemText 
          primary="TechGrowth Ltd"
          secondary="Head of Digital Marketing | 2010 - 2015"
        />
      </ListItem>
    </List>
  </Paper>
);

export default Experience;