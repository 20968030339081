import React from 'react';
import { 
  Typography, 
  Box,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

const MetricsList = ({ metrics }) => {
  if (!metrics?.length) return null;
  return (
    <List dense disablePadding>
      {metrics.map((metric, index) => (
        <ListItem key={index} disableGutters>
          <ListItemText
            primary={
              <Box>
                <Typography variant="body2" component="span" color="text.secondary">
                  {metric.type}:{' '}
                </Typography>
                <Typography variant="body2" component="span">
                  {metric.value}
                </Typography>
              </Box>
            }
            secondary={metric.description && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {metric.description}
              </Typography>
            )}
          />
        </ListItem>
      ))}
    </List>
  );
};

const DirectReportsList = ({ reports }) => {
  if (!reports?.length) return null;
  return (
    <List dense disablePadding>
      {reports.map((report, index) => (
        <ListItem key={index} disableGutters>
          <ListItemText
            primary={
              <Typography variant="body2">
                {report.job_title}
              </Typography>
            }
            secondary={
              (report.function || report.geographic_scope) && (
                <Typography variant="body2" color="text.secondary">
                  {[report.function, report.geographic_scope]
                    .filter(Boolean)
                    .join(' • ')}
                </Typography>
              )
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const Section = ({ label, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const RoleScope = ({ details }) => {
  if (!details) return null;

  const { reporting, metrics, notes } = details;

  return (
    <Stack spacing={2} divider={<Divider />}>
      {/* Reporting Structure */}
      {reporting && (
        <Stack spacing={2}>
          {reporting.position_context && (
            <Typography variant="body2">
              {reporting.position_context}
            </Typography>
          )}
          
          {reporting.direct_reports_details?.length > 0 && (
            <Section label="Direct Reports">
              <DirectReportsList reports={reporting.direct_reports_details} />
            </Section>
          )}

          {reporting.indirect_reports_details && (
            <Section label="Indirect Reports">
              <Typography variant="body2">
                {reporting.indirect_reports_details.total_number} total reports
              </Typography>
              {reporting.indirect_reports_details.breakdown && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                  {reporting.indirect_reports_details.breakdown}
                </Typography>
              )}
            </Section>
          )}
        </Stack>
      )}

      {/* Metrics */}
      {metrics && (
        <Stack spacing={2}>
          {metrics.key_metrics?.length > 0 && (
            <Section label="Key Metrics">
              <MetricsList metrics={metrics.key_metrics} />
            </Section>
          )}
          
          {metrics.additional_context && (
            <Typography variant="body2" color="text.secondary">
              {metrics.additional_context}
            </Typography>
          )}
        </Stack>
      )}

      {/* Additional Notes */}
      {notes && (
        <Typography variant="body2" color="text.secondary">
          {notes}
        </Typography>
      )}
    </Stack>
  );
};

export default RoleScope;