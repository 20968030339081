import { useState, useEffect } from 'react';
import useAPIWithAuth from './useAPIWithAuth';

export default function useHasAccount(isAuthenticated) {
  const [hasAccount, setHasAccount] = useState();
  const api = useAPIWithAuth();

  useEffect(() => {
    const checkAccount = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get('/waitlist/');
          if (response.status === 200) {
            setHasAccount("ready");
          } else {
            setHasAccount("waiting");
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setHasAccount(false);
          } else {
            throw error;
          }
        }
      }
    };

    checkAccount();
  }, [isAuthenticated, api]); // Run effect on authentication state change

  return { hasAccount };
}
