import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box
} from '@mui/material';
import { 
  Person as PersonIcon,
  Email as EmailIcon,
  Business as BusinessIcon 
} from '@mui/icons-material';

const RecommendationItem = ({ recommendation }) => {
  if (!recommendation) return null;

  return (
    <ListItem 
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '&:not(:last-child)': { mb: 2 }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="body1" component="span">
              {recommendation.full_name}
            </Typography>
          }
        />
      </Box>

      {recommendation.email && (
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            ml: 7,
            color: 'text.secondary'
          }}
        >
          <EmailIcon sx={{ fontSize: 16, mr: 1 }} />
          <Typography variant="body2">
            {recommendation.email}
          </Typography>
        </Box>
      )}

      {recommendation.details && (
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'flex-start', 
            ml: 7,
            color: 'text.secondary',
            mt: 0.5
          }}
        >
          <BusinessIcon sx={{ fontSize: 16, mr: 1, mt: 0.5 }} />
          <Typography variant="body2">
            {recommendation.details}
          </Typography>
        </Box>
      )}
    </ListItem>
  );
};

const Recommendations = ({ recommendations }) => {
  if (!recommendations?.length) return null;

  return (
    <Stack spacing={2}>
      <List disablePadding>
        {recommendations.map((recommendation, index) => (
          <RecommendationItem 
            key={`${recommendation.full_name}-${index}`} 
            recommendation={recommendation}
          />
        ))}
      </List>
    </Stack>
  );
};

export default Recommendations;