import { useState, useContext, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useWebSocket } from '../../hooks/useWebSocket';
import { ChatUI } from './ChatUI';
import { GlobalStateContext } from '../../global_state_context';

export const ChatContainer = ({ websocketUrl, requireAuthentication }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);  // Initialize as true
  const context = useContext(GlobalStateContext) || {};
  const { dispatch } = context;

  const updateMessageById = (prevMessages, newMessage) => {
    const existingIndex = prevMessages.findIndex(msg => msg.id === newMessage.id);
    if (existingIndex !== -1) {
      return [
        ...prevMessages.slice(0, existingIndex),
        newMessage,
        ...prevMessages.slice(existingIndex + 1)
      ];
    }
    return [...prevMessages, newMessage];
  };

  const messageHandler = (event) => {
    const data = JSON.parse(event.data);
    if (Array.isArray(data)) {
      setMessages(data); // Simply use the server's state
      setIsLoading(false);  // Turn off loading after initial messages load
    } else if (data === 'heartbeat') {
      socket.send('"heartbeat"');
    } else {
      setMessages(prevMessages => updateMessageById(prevMessages, data));
      setIsLoading(false);
      if (dispatch) {
        dispatch({ type: 'INCREMENT_MESSAGE_COUNT' });
      }
    }
  };

  const { isConnected, sendMessage, socket } = useWebSocket(websocketUrl, messageHandler, requireAuthentication);

  // Turn off loading if connection fails
  useEffect(() => {
    if (!isConnected) {
      setIsLoading(false);
    }
  }, [isConnected]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSendMessage = async (message) => {
    setIsLoading(true);
    const newMessage = { role: 'user', content: message, id: messages.length };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    try {
      await sendMessage(newMessage);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <ChatUI
      messages={messages}
      isConnected={isConnected}
      isLoading={isLoading}
      onSendMessage={handleSendMessage}
      isMobile={isMobile}
    />
  );
};

export default ChatContainer;