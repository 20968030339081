import React from 'react';
import { Container, Typography, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import LoginButton from '../login';

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      maxWidth="lg"
      style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0 }}
    >
      <Grid container style={{ height: '100%', margin: 0, width: '100%' }}>
        {!isMobile && (
          <Grid
            item
            xs={6}
            style={{ backgroundColor: '#fff9e6', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <Box>
              <Typography variant="h4" color="orange" fontWeight="bold" gutterBottom>
                Nexco
              </Typography>
              <Typography variant="h6" color="orange">
                your AI assistant for maximising your career growth
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={isMobile ? 12 : 6}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: isMobile ? '2rem' : '0' }}
        >
          <Typography variant="h4" gutterBottom>
            Get started
          </Typography>
          <Box mt={2} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoginButton redirectUri={`${window.location.origin}`} buttonText="Login" />
            <LoginButton redirectUri={`${window.location.origin}/waitlist`} buttonText="Join Waitlist" />
          </Box>
        </Grid>
      </Grid>
      <Box style={{ position: 'absolute', bottom: '2rem', textAlign: 'center', width: '100%' }}>
        <Typography variant="body2" color="textSecondary">
          <a href="/terms" style={{ textDecoration: 'none', color: 'inherit' }}>Terms of use</a> | <a href="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy policy</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default HomePage;
