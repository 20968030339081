import { useRef, useEffect, useMemo } from 'react';
import { List, ListItem, ListItemText, Avatar } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as AssistantIcon } from '../../assets/assistant.svg';
import { ReactComponent as LoadingIcon } from '../../assets/assistant_loading.svg';

export const MessageList = ({
  messages,
  isMobile,
  isLoading,
}) => {
  const chatEndRef = useRef(null);
  const { user } = useAuth0();
  
  // Show loading state if explicitly loading OR if the list is empty
  const shouldShowLoading = useMemo(() => 
    isLoading || messages.length === 0,
    [isLoading, messages.length]
  );

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <List style={{ flexGrow: 1, overflowY: 'auto', padding: '1rem' }}>
      {messages.map((message) => (
        <ListItem key={message.id}>
          {!isMobile && (
            message.role === 'assistant' ? (
              <AssistantIcon sx={{ mr: 1 }} />
            ) : message.role === 'user' && (
              <Avatar
                src={user?.picture}
                alt={user?.name || 'User avatar'}
                sx={{ mr: 0.5 }}
                imgProps={{ referrerPolicy: "no-referrer" }}
              />
            )
          )}
          <ListItemText
            primary={
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {message.content}
              </div>
            }
            sx={{
              bgcolor: message.role === 'user' ? 'background.paper' : 'background.halfway',
              padding: isMobile ? '0.5rem' : '0.5rem 1rem',
              borderRadius: '10px',
            }}
          />
        </ListItem>
      ))}
      {shouldShowLoading && (
        <ListItem style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          {!isMobile && (
            <LoadingIcon style={{ marginRight: '1rem' }} />
          )}
          <ListItemText
            primary="Loading..."
            sx={{
              color: 'text.secondary',
              '& .MuiListItemText-primary': {
                color: 'text.secondary'
              }
            }}
          />
        </ListItem>
      )}
      <div ref={chatEndRef} />
    </List>
  );
};