import React, { useState } from 'react';
import { Paper, Typography, Tabs, Tab, Box, List, ListItem, ListItemText } from '@mui/material';
import { EmojiEvents } from '@mui/icons-material';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PerformanceAchievements = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <EmojiEvents sx={{ mr: 1 }} /> Performance and Achievements
      </Typography>

      <Tabs value={value} onChange={handleChange} aria-label="role tabs">
        <Tab label="TechInnovate Inc." />
        <Tab label="InnoSoft Solutions" />
        <Tab label="TechGrowth Ltd" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <List dense>
          <ListItem>
            <ListItemText 
              primary="Digital Transformation Initiative" 
              secondary="Led company-wide program, resulting in 40% improvement in customer engagement metrics. Implemented AI-driven personalization engine, increasing conversion rates by 35% and customer lifetime value by 28%. Completed 2 months ahead of schedule and 5% under budget."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Brand Revitalization" 
              secondary="Orchestrated comprehensive rebranding, increasing brand value by 45% in 24 months. Launched new brand identity and messaging framework that resonated strongly with millennial and Gen Z demographics."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Data-Driven Marketing Strategy" 
              secondary="Developed and implemented advanced customer segmentation strategy using machine learning algorithms. Increased marketing ROI by 50% and reduced customer acquisition costs by 30%."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Global Market Expansion" 
              secondary="Led marketing efforts for expansion into Brazil, India, and Australia. Exceeded revenue targets by 22% in the first year of operations in each new market."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Sustainability Campaign" 
              secondary="Launched 'TechForGood' campaign, improving brand perception among millennials and Gen Z by 55%. Campaign won two industry awards for creativity and social impact."
            />
          </ListItem>
        </List>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <List dense>
          <ListItem>
            <ListItemText 
              primary="Revenue Growth" 
              secondary="Grew marketing-attributed revenue from $50M to $200M in 5 years."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Team Leadership" 
              secondary="Led a team of 40 across 3 continents, improving employee engagement scores by 40%."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Account-Based Marketing" 
              secondary="Implemented the company's first account-based marketing program, resulting in a 60% increase in enterprise client acquisition."
            />
          </ListItem>
        </List>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <List dense>
          <ListItem>
            <ListItemText 
              primary="Digital Marketing Function" 
              secondary="Built the company's digital marketing function from the ground up."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Lead Generation" 
              secondary="Increased online lead generation by 300% over 3 years."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Content Marketing" 
              secondary="Launched an award-winning content marketing program that positioned the company as an industry thought leader."
            />
          </ListItem>
        </List>
      </TabPanel>
    </Paper>
  );
};

export default PerformanceAchievements;