import { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useWebSocket = (path, onMessage, requireAuthentication) => {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const socketRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const onMessageRef = useRef(onMessage);

  // Update the ref when onMessage changes
  useEffect(() => {
    onMessageRef.current = onMessage;
  }, [onMessage]);

  const getWebSocketUrl = useCallback(() => {
    if (process.env.NODE_ENV === 'production') {
      return `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}${path}`;
    } else {
      return `ws://localhost:8000${path}`;
    }
  }, [path]);

  const sendMessage = useCallback(async (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      if(requireAuthentication) {
        const token = await getAccessTokenSilently({
          audience: "https://api.nextco.ai",
          scope: "openid profile email offline_access"
        });
        socketRef.current.send(JSON.stringify({ message, token }));
      }else{
        socketRef.current.send(JSON.stringify({ message }));
      }
    } else {
      setError("WebSocket is not connected. Please try again.");
    }
  }, [getAccessTokenSilently, requireAuthentication]);

  const setupWebSocket = useCallback(async () => {
    try {
      var wsUrl;
      if(requireAuthentication){
        const token = await getAccessTokenSilently({
          audience: "https://api.nextco.ai",
          scope: "openid profile email offline_access"
        });
        wsUrl = `${getWebSocketUrl()}?token=${token}`;
      }else{
        wsUrl = getWebSocketUrl();
      }
      
      socketRef.current = new WebSocket(wsUrl);

      // Use the ref for onMessage
      if (onMessageRef.current) {
        socketRef.current.onmessage = (event) => onMessageRef.current(event, sendMessage);
      }

      socketRef.current.onopen = () => {
        setIsConnected(true);
        setError(null);
      };

      socketRef.current.onclose = (event) => {
        setIsConnected(false);
        setError('WebSocket disconnected. Trying to reconnect...');
        setTimeout(setupWebSocket, 5000);
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
        setError('WebSocket error occurred. Please try again later.');
      };
    } catch (error) {
      console.error("Error setting up WebSocket", error);
      setError("Error connecting. Please try again.");
    }
  }, [getAccessTokenSilently, getWebSocketUrl, sendMessage, requireAuthentication]);

  useEffect(() => {
    setupWebSocket();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [setupWebSocket]);

  return { isConnected, error, sendMessage, socket: socketRef.current };
};