import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';

const useAPIWithAuth = () => {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: '/api',
    });
  }, []);

  useEffect(() => {
    const setInterceptor = async () => {
      axiosInstance.interceptors.request.use(
        async (config) => {
          try {
            const token = await getAccessTokenSilently({
              audience: "https://api.nextco.ai",
              scope: "openid profile email offline_access"
            });
            if (token) {
              config.headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error("Error fetching the token", error);
            // Optionally handle token fetch errors, such as re-authenticating
            if (error.error === 'login_required' || error.error === 'consent_required') {
              loginWithRedirect();
            }
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      axiosInstance.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 403) {
            logout({
              returnTo: window.location.origin,
            });
          }
          return Promise.reject(error);
        }
      );
    };

    setInterceptor();

    return () => {
      axiosInstance.interceptors.request.eject(setInterceptor);
      axiosInstance.interceptors.response.eject(setInterceptor);
    };
  }, [getAccessTokenSilently, loginWithRedirect, logout, axiosInstance]);

  return axiosInstance;
};

export default useAPIWithAuth;
