import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';
import { 
  Phone as PhoneIcon,
  Email as EmailIcon 
} from '@mui/icons-material';

const ContactList = ({ items, icon: Icon }) => {
  if (!items?.length) return null;
  return (
    <List dense disablePadding>
      {items.map((item, index) => (
        <ListItem 
          key={index} 
          disableGutters
          sx={{ alignItems: 'flex-start' }}
        >
          <Icon 
            sx={{ 
              fontSize: 16, 
              mt: 0.5, 
              mr: 1, 
              color: 'text.secondary' 
            }} 
          />
          <ListItemText
            primary={
              <Typography variant="body2">
                {item.number || item.email}
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.secondary">
                {item.label}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const Section = ({ label, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const ContactPreferences = ({ details }) => {
  if (!details) return null;

  const { phone_numbers, email_addresses, contact_preference } = details;

  return (
    <Stack spacing={2}>
      {/* Contact Methods */}
      {phone_numbers?.length > 0 && (
        <ContactList items={phone_numbers} icon={PhoneIcon} />
      )}
      
      {email_addresses?.length > 0 && (
        <ContactList items={email_addresses} icon={EmailIcon} />
      )}

      {/* Contact Preferences */}
      {contact_preference && (
        <Section label="Contact Preferences">
          <Typography variant="body2">
            Primary: {contact_preference.primary_method}
          </Typography>
          
          {contact_preference.follow_up_method && (
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              Secondary: {contact_preference.follow_up_method}
            </Typography>
          )}
          
          {contact_preference.additional_notes && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {contact_preference.additional_notes}
            </Typography>
          )}
        </Section>
      )}
    </Stack>
  );
};

export default ContactPreferences;