import React from 'react';
import { Paper, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { Email, WhatsApp } from '@mui/icons-material';

const ContactDetails = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>Contact Details</Typography>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: isSmallScreen ? 'flex-start' : 'center', 
        mb: 1 
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mr: isSmallScreen ? 0 : 2,
          mb: isSmallScreen ? 1 : 0
        }}>
          <Email sx={{ mr: 1 }} />
          <Typography variant="body1">Work email: sarah.johnson@techinnovate.com</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <WhatsApp sx={{ mr: 1 }} />
          <Typography variant="body1">WhatsApp: +44 7456 876945</Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        The best way to reach Sarah is to double up with an email to her work email address as well as a WhatsApp to her personal mobile. It sometimes takes her a day or two to reply, but she tends not to leave messages unanswered.
      </Typography>
    </Paper>
  );
};

export default ContactDetails;