import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import * as amplitude from '@amplitude/analytics-browser';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
      amplitude.track('Page View', {
        path: location.pathname,
        search: location.search,
        url: window.location.href,
        title: document.title,
        referrer: document.referrer
      });
    }
  }, [location]);
};
