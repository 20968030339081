import React from 'react';
import { Box, Typography } from '@mui/material';

export const ProfileContainer = ({ children, loading, error }) => (
  <Box
    sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}
  >
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        width: '100%',
        maxWidth: 800,
        mx: 'auto',
        p: 2,
        boxSizing: 'border-box'
      }}
    >
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        children
      )}
    </Box>
  </Box>
);