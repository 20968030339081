import React from 'react';
import { Paper, Typography, Box, Chip } from '@mui/material';
import { CheckCircle, Info } from '@mui/icons-material';

const ReadinessForChange = () => {
  const readinessLevel = 'Open to approach calls';

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>Openness to approach</Typography>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <CheckCircle color="primary" sx={{ mr: 1 }} />
        <Typography variant="body1">
          {readinessLevel}
        </Typography>
      </Box>

      <Typography variant="body2" paragraph>
        Sarah is open to approach calls. Whilst satisfied in her current role, she is open to exploring opportunities that align with her medium and long term career aspirations.
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Info color="primary" sx={{ mr: 1 }} />
        <Typography variant="body2">
          Sarah is also open to "left-field" ideas, but feels fairly confident that her career will continue in marketing in mid to large tech companies.
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Chip 
          label="Open to new opportunities" 
          sx={{ 
            mr: 1, 
            mb: 1, 
            backgroundColor: '#f0f0f0', 
            color: '#555', 
            borderRadius: '8px', 
            border: '1px solid #ddd', 
            fontSize: '0.85rem', 
            fontWeight: 400, 
            cursor: 'default', 
            '&:hover': { backgroundColor: '#f0f0f0' } 
          }} 
        />
        <Chip 
          label="Satisfied in current role" 
          sx={{ 
            mr: 1, 
            mb: 1, 
            backgroundColor: '#f0f0f0', 
            color: '#555', 
            borderRadius: '8px', 
            border: '1px solid #ddd', 
            fontSize: '0.85rem', 
            fontWeight: 400, 
            cursor: 'default', 
            '&:hover': { backgroundColor: '#f0f0f0' } 
          }} 
        />
        <Chip 
          label="Interested in career growth" 
          sx={{ 
            mr: 1, 
            mb: 1, 
            backgroundColor: '#f0f0f0', 
            color: '#555', 
            borderRadius: '8px', 
            border: '1px solid #ddd', 
            fontSize: '0.85rem', 
            fontWeight: 400, 
            cursor: 'default', 
            '&:hover': { backgroundColor: '#f0f0f0' } 
          }} 
        />
      </Box>

    </Paper>
  );
};

export default ReadinessForChange;